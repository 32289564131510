const host = {
  localhost: {
    apiUrl: "https://www.xclusit.com:4040",
    coupon_bucket: "local_coupon_upload",
    logo_bucket: "local_logo_upload",
    banner_bucket: "local_banner_upload"
  },
  "dev-dashboard.coupify.io": {
    apiUrl: "https://dev-api.coupify.io",
    coupon_bucket: "dev_coupon_upload",
    logo_bucket: "dev_logo_upload",
    banner_bucket: "dev_banner_upload"
  },
  "qa-dashboard.coupify.io": {
    apiUrl: "https://qa-api.coupify.io",
    coupon_bucket: "qa_coupon_upload",
    logo_bucket: "qa_logo_upload",
    banner_bucket: "qa_banner_upload"
  },
  "demo-dashboard.coupify.io": {
    apiUrl: "https://demo-api.coupify.io",
    coupon_bucket: "demo_coupon_upload",
    logo_bucket: "demo_logo_upload",
    banner_bucket: "demo_banner_upload"
  },
  "staging-dashboard.coupify.io": {
    apiUrl: "https://staging-api.coupify.io",
    coupon_bucket: "staging_coupon_upload",
    logo_bucket: "staging_logo_upload",
    banner_bucket: "staging_banner_upload"
  },
  "dashboard.coupify.io": {
    apiUrl: "https://api.coupify.io",
    coupon_bucket: "coupon_upload",
    logo_bucket: "logo_upload",
    banner_bucket: "local_banner_upload"
  },
  "http://xclusit.com": {
    apiUrl: "https://www.xclusit.com:4040",
    coupon_bucket: "local_coupon_upload",
    logo_bucket: "local_logo_upload",
    banner_bucket: "local_banner_upload"
  },
}

var location = window.location;
var service_url = '';
if (location.hostname == 'localhost') {
  service_url = location.protocol + "//" + location.hostname + ':4040'
} else {
  service_url = location.protocol + "//" + location.hostname + ':4040'
}

export default {
  defaultPath: '/auth/signin', //'dashboard-page', // '/sample-page', // '/auth/signin-1',
  basename: '', // only at build time to set, like /datta-able
  layout: 'vertical', // vertical, horizontal
  preLayout: 'layout-3', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: false, // mini-menu
  layoutType: 'menu-light', // menu-dark, menu-light, dark
  navIconColor: true,
  headerBackColor: 'header-blue', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: 'navbar-dark', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  navDropdownIcon: 'style1', // style1, style2, style3
  navListIcon: 'style2', // style1, style2, style3, style4, style5, style6
  navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: false,
  layout6Background: '#999999', //'linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 55%, #FFFFFF 100%)', //'linear-gradient(to bottom, #50BAFE 0%, #D1E8FB 55%, #FFFFFF 100%)', // used only for pre-layout = layout-6
  layout6BackSize: '', // used only for pre-layout = layout-6
  // apiUrl: host[window.location.hostname].apiUrl,
  // apiUrl: "http://ec2-44-206-245-143.compute-1.amazonaws.com:4040", // dev 2
  // apiUrl: "https://b3b3-2405-201-200c-9193-c15-16ff-ae3a-9a47.ngrok-free.app/api/v1", // ngrok db testing 1
  // apiUrl: "http://192.168.29.173:4040/api/v1",
  // apiUrl: "https://dev.xclusit.com:4040/api/v1", // dev
  apiUrl: "https://xclusit.app:4040/api/v1", // production
  //apiUrl: "http://localhost:4040", // local server 
  // apiUrl: service_url,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
  // cloudinary_upload_url: 'https://www.xclusit.com:4040',
  cloudinary_upload_url: 'http://44.209.193.210:4040',
  //\ cloudinary_upload_url: 'http://localhost:4040',
  // cloudinary_upload_url: 'https://api.cloudinary.com/v1_1/coupify/image/upload',
  cloudinary_get_url: "",
  logo_bucket: host.localhost.apiUrl,

  //  logo_bucket: host["http://74.208.206.18:4040"].logo_bucket,

  //  coupon_bucket: host["http://74.208.206.18:4040"].coupon_bucket,
  //  banner_bucket: host["http://74.208.206.18:4040"].banner_bucket,


  // logo_bucket: host[window.location.hostname].logo_bucket,
  // coupon_bucket: host[window.location.hostname].coupon_bucket,
  // banner_bucket: host[window.location.hostname].banner_bucket,
  states: ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],

  aws_bucket_name: 'xclusit-dev',
  // aws_bucket_access_key: 'YnjrAQGQO2eOsGmtRZfAAPYefhTj397pi4mmWS/N',
  // aws_bucket_access_key_ID: 'AKIAWRFQENW7YQJ72EEQ',
  // aws_bucket_access_key: 'AqNejC8nEwG4LrxhLcAa7AgIxaiPb/goJAVF5Ek0',
  // aws_bucket_access_key_ID: 'AKIAWRFQENW7SKP3RZ5T',
  // aws_bucket_access_key: 'yiY0V0mm2ngzQpShEqGpOByntoqA6wPSnEuRPxZX',
  // aws_bucket_access_key_ID: 'AKIAWRFQENW77ZTDWGWV',
  aws_bucket_access_key: 'anh7eWsdYtqOyHKyTQliPs2d4i+fm6MEDOJsWozs',
  aws_bucket_access_key_ID: 'AKIAWRFQENW74FM6TTEX',
  aws_region_name: 'us-east-1',

  awsBucketUrl: "https://xclusit-dev.s3.amazonaws.com"
};
